import Test from '../../../Data/Dummy.Json';
export default {
    data() {
        return {
            props: {},
            search: "",
            headers: [{
                    text: "Nro. autorización",
                    value: "number",
                    sortable: false
                },
                {
                    text: "Estado autorización ",
                    sortable: false,
                    value: "status"
                },
                {
                    text: "Fecha de generación",
                    sortable: false,
                    value: "generationDate"
                },
                {
                    text: "Origen de servicio",
                    value: "serviceOrigin",
                    sortable: false
                },

                {
                    text: "Medicamentos / Insumos",
                    value: "medicine",
                    sortable: false
                },
                {
                    text: "Nombre del prestador",
                    value: "providerId",
                    sortable: false
                },
                {
                    text: "Formula",
                    value: "Formula",
                    sortable: false
                },
                {
                    text: "Número de lote",
                    value: "numLot",
                    sortable: false
                },
                {
                    text: "Imprimir",
                    value: "print",
                    sortable: false
                }

            ],
            itemsData: [{
                number: 1,
                plu: 1,
                status: "Activo",
                generationDate: "2019-07-07",
                serviceOrigin: "Enfermedad General",
                medicine: "Acetaminofen",
                providerId: "Farmadomicilios",
            }]
        };
    },
    props: {
        order: {
            type: Object,
            default: () => {}
        }
    },
    watch: {},
    mounted() {},
    methods: {
        onRowClick(item) {
            this.$emit("row-click", item);
        }
    }
};