var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    [
      _c(
        "v-expansion-panel",
        { attrs: { expand: "" } },
        [
          _c(
            "v-expansion-panel-content",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "title primary--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("outcome.patient-data")) +
                            " - " +
                            _vm._s(_vm.order.idPatient.name) +
                            " "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "v-flex",
                { attrs: { md12: "" } },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { label: "Tipo Documento" },
                            model: {
                              value:
                                _vm.order.idPatient.idIdentificationType.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.order.idPatient.idIdentificationType,
                                  "name",
                                  $$v
                                )
                              },
                              expression:
                                "order.idPatient.idIdentificationType.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { label: "Nro. Documento" },
                            model: {
                              value: _vm.order.idPatient.identification,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.order.idPatient,
                                  "identification",
                                  $$v
                                )
                              },
                              expression: "order.idPatient.identification",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { label: "Dirección" },
                            model: {
                              value: _vm.order.idPatient.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.order.idPatient, "address", $$v)
                              },
                              expression: "order.idPatient.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel-content",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_c("div", [_vm._v(" Segundo ")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "v-flex",
                { attrs: { md12: "" } },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { label: "Documento" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel-content",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_c("div", [_vm._v(" Tercer ")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "v-flex",
                { attrs: { md12: "" } },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { label: "Documento" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }